<template>
  <div class="columns is-multiline">
    <h2 class="column is-12 payment-method-title">Available Payment Method</h2>
    <div class="column is-12 payment-method-subtitle py-0">Cards</div>
    <div class="column is-12 payment-method-img-container">
      <img
        v-for="(card, c) in cards"
        :key="c"
        class="payment-method-img"
        :src="card.url"
      />
    </div>
    <div class="column is-12 payment-method-subtitle py-0">Qris</div>
    <div class="column is-12 payment-method-img-container">
      <img src="@/assets/images/Qris/qris.svg" class="payment-method-img" />
    </div>
    <div class="column is-12 payment-method-subtitle py-0">E-Wallets</div>
    <div class="column is-12 payment-method-img-container">
      <img
        v-for="(eWallet, e) in eWallets"
        :key="e"
        class="payment-method-img"
        :src="eWallet.url"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          url: require('@/assets/images/Cards/visa.svg'),
        },
        {
          url: require('@/assets/images/Cards/master-card.svg'),
        },
        {
          url: require('@/assets/images/Cards/amex.svg'),
        },
        {
          url: require('@/assets/images/Cards/jcb.svg'),
        },
      ],
      eWallets: [
        {
          url: require('@/assets/images/EWallets/gopay.svg'),
        },
        {
          url: require('@/assets/images/EWallets/ovo.svg'),
        },
        {
          url: require('@/assets/images/EWallets/dana.svg'),
        },
      ],
    }
  },
}
</script>
